import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Layout from '../components/layout';

const BlogHeader = styled.div`
  margin: 0 auto 2rem auto;
  text-align: center;

  h1 {
    position: relative;
    display: inline-block;
    font-size: 2.2em;

    &::after {
      content: '';
      width: 100%;
      height: 5px;
      position: absolute;
      bottom: -5px;
      left: 0;
      background: #ff9966;
      background: linear-gradient(to right, #ff5e62, #ff9966);
      transform: scaleX(0.95);
    }
  }
`;

const Content = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 2rem 0 0.5rem 0;
  }

  p {
    margin: 1rem 0;
    line-height: 1.6;
  }

  a {
    color: #b500ad;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  li {
    margin: 0.6rem 2rem;
  }

  ol {
    list-style: decimal;
  }
`;

export default ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;

  return (
    <Layout title={frontmatter.title}>
      <BlogHeader>
        <h1>{frontmatter.title}</h1>
      </BlogHeader>
      <Content dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`;
