import React, { useState } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Menu from './menu';
import Hamburger from './hamburger';
import '../styles/base.scss';

const Container = styled.div`
  max-width: 960px;
  width: 100%;
  margin: 20px auto 0 auto;
  padding: 15px 35px;
  border-radius: 8px;
  background: #ececec;
  box-shadow: 0px -1px 10px 2px rgba(0, 0, 0, 0.2);
`;

const FixedHeader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 2rem;
`;

const Layout = ({ children, title }) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <Container>
      <Helmet title={title} />
      <FixedHeader>
        <Hamburger isOpen={showMenu} onClick={() => setShowMenu(!showMenu)} />
      </FixedHeader>
      {showMenu ? <Menu /> : children}
    </Container>
  );
};

export default Layout;
