import React from 'react';
import styled from 'styled-components';

const StyledHamburger = styled.div`
  display: inline-block;
  cursor: pointer;
  >: nth-child(1) {
    ${({ isOpen }) =>
      isOpen &&
      `transform: rotate(-45deg) translate(-8px, 6px); background: #f953c6;
      background: linear-gradient(to right, #b91d73, #f953c6)`};
  }

  > :nth-child(2) {
    ${({ isOpen }) => isOpen && `opacity: 0`};
  }

  > :nth-child(3) {
    ${({ isOpen }) =>
      isOpen &&
      `transform: rotate(45deg) translate(-9px, -8px);background: #f953c6;
      background: linear-gradient(to right, #b91d73, #f953c6)`};
  }
`;

const Bar = styled.div`
  width: 35px;
  height: 5px;
  margin: 6px 0;
  border-radius: 3px;
  transition: all 0.2s;
  background: #ff9966;
  background: linear-gradient(to right, purple, rebeccapurple);
`;

const Hamburger = ({ isOpen, onClick }) => (
  <StyledHamburger isOpen={isOpen} onClick={onClick}>
    <Bar />
    <Bar />
    <Bar />
  </StyledHamburger>
);

export default Hamburger;
