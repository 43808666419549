import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledMenu = styled.nav`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  width: 100%;
`;

const StyledLink = styled(Link)`
  font-size: 1.65rem;
  text-decoration: none;
  color: inherit;
  margin: 0 auto;
  padding: 5px;
  &:not(:first-child) {
    margin-top: 1rem;
  }
`;

const Menu = () => {
  return (
    <StyledMenu>
      <StyledLink to="/">Home</StyledLink>
      <StyledLink to="/about">About</StyledLink>
    </StyledMenu>
  );
};

export default Menu;
